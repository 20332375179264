
import { useState, useEffect } from 'react';
import '../../style/MainSlide.css';

function MainSlide() {

    var [imgIndex, setImgIndex] = useState(1)


    useEffect(() => {
        const interval = setInterval(() => {
            if (imgIndex !== 5) {
                setImgIndex(imgIndex++)
            } else {
                setImgIndex(1)
            }
            
        }, 2000);

        return () => clearInterval(interval);

    }, [imgIndex]);


   

    return (
        <div className="MainSlide">
            <img className="mainSlideImg" src={require(`../../images/mainSlide/image${imgIndex}.png`)} />
            <center>
                <div className="mainSlideHandControler">

                    <button className="mainSlideHandControlerItem" style={imgIndex===1?{"background-color": "#DC7633"}:{}} onClick={(e)=>{setImgIndex(1)}}></button>
                    <button className="mainSlideHandControlerItem" style={imgIndex===2?{"background-color": "#DC7633"}:{}} onClick={(e)=>{setImgIndex(2)}}></button>
                    <button className="mainSlideHandControlerItem" style={imgIndex===3?{"background-color": "#DC7633"}:{}} onClick={(e)=>{setImgIndex(3)}}></button>
                    <button className="mainSlideHandControlerItem" style={imgIndex===4?{"background-color": "#DC7633"}:{}} onClick={(e)=>{setImgIndex(4)}}></button>


                </div>
            </center>
        </div>
    )

    
}

export default MainSlide