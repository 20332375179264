function Footer() {
    return (<div className="footer">

            <br/>
            +374 98 580146
            <br/>
            website by <a href="https://alenabrahamyan.herokuapp.com">Alen Abrahamyan</a>
            <br />
            © 2022 thefactory.am
        

    </div>)
}

export default Footer;