import './style/Header.css';
import {useState} from 'react';

function Header() {

    var [ showMobileBar, setShowMobileBar ] = useState(false)
    return (
        <>
            <div className="headerMenu">
                <a href="/"><img className='headerLogo' src={require("./images/icons/logo.png")} /></a>
                <div className='menuBox'>

                    <a href='/' className='headerMenuLink'>Home</a>
                    <a href='/about' className='headerMenuLink'>About us</a>
                    <a href='/gallery' className='headerMenuLink'>Gallery</a>
                    <a href='/contacts' className='headerMenuLink'>Contacts</a>

                </div>
            </div>
            <div className='mobileMenuButtonContenier'>
                    <center>
                    <img onClick={e => { 
                        setShowMobileBar(!showMobileBar)
                     }} className='mobileMenuButton' src={require("./images/icons/mobile_menu.png")} />
                    </center>
                </div>
                {showMobileBar ? (<div className='mobileMenu'>
                <a href='/' className='mobileHeaderMenuLink'>Home</a>
                <a href='/about' className='mobileHeaderMenuLink'>About us</a>
                <a href='/gallery' className='mobileHeaderMenuLink'>Gallery</a>
                <a href='/contacts' className='mobileHeaderMenuLink'>Contacts</a>
            </div>) : (<div></div>)}
            
        </>
    )
}

export default Header