import "../../style/Gallery.css"; 

function ShowImg({img}){
return(
    <div className="showImgBox">
        <a href="#" className="closeImg" onClick={e=>{
           e.preventDefault();
           window.history.go(-1);
        }}>X</a>
        <center>
            
                <img className="imgTagInShowImg" src={window.location+".jpg"} />
            
        </center>
    </div>
)
}

export default ShowImg;