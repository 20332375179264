// //<img src={require("./images/icons/asa.png")} />

import React from "react";
import './style/App.css';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Components/home/Home";
import About from "./Components/About";
import Gallery from "./Components/galery/Gallery";
import Contacts from "./Components/Contacts"; 


function App() {
  return (
    <>
    <Header />
    
    <Router>
        <Switch>
          
          <Route path="/about"> <About /> </Route>
          <Route path="/gallery" > <Gallery /> </Route>
          <Route path="/contacts"> <Contacts /> </Route>
          <Route path="/"> <Home /> </Route>
          
        </Switch>
    </Router>

    <Footer/>
    </>
  );
}


export default App;


