import {useState, useEffect} from "react";
import "../../style/Gallery.css"; 
import ListImg from "./ListImg";
import ShowImg from "./ShowImg";




function Gallery(){
    let [path, setPath] = useState(window.location.pathname.substring(9))
    let [showList, setShowList] = useState(true)
    let [imgShowBlock, setImgShowBlock] = useState(true)
   
   useEffect(()=>{
    if(path.length===0){
            setShowList(true);
        }else{
            setShowList(false)
        }
   },[])
    

    return(
        
        
            showList ? (<ListImg/>) : (<ShowImg />)
        

        
    )
}

export default Gallery;