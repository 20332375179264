import MainSlide from "./MainSlide";
import ToAbout from "./ToAbout";
import ToGallery from "./ToGallery";
import WorkProcess from "./WorkProcess";


function Home() {
    return (
        <>
            <center>
                <MainSlide />
            </center>
            <ToAbout />
            <ToGallery />
            <WorkProcess />
        </>
    )
}

export default Home;