import "../../style/Gallery.css"

function WorkProcess(){

    return(
        <>
        <center>
        <h1>
            Work Process
        </h1>
        <div>
            <a href="/gallery/59"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/59.jpg"} /></a>
            <a href="/gallery/90"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/90.jpg"} /></a>
            <a href="/gallery/80"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/80.jpg"} /></a>
            <a href="/gallery/46"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/46.jpg"} /></a>
            <a href="/gallery/61"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/61.jpg"} /></a>
            
        </div>
        </center>
        </>
    )
}

export default WorkProcess;