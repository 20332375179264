import '../style/Contacts.css';

function Contacts(){
    return(
        <div className="mainContactsBox">
            <center>
                <h1>Contacts</h1>
                <p className='mainContactsText'>
                Phone: +374 98 580146
                <br/>
                E-mail: info@thefactory.am 
                     <br/>
                     Location: Armenia,Yerevan
                                </p>
            </center>
        </div>
    )
}

export default Contacts;