import '../../style/About.css';

function ToAbout(){

return(
    <div>
        <center>
            <h1>About Us</h1>
            <p className='mainAboutText'>Welcome to The Factory, your number one source for modern interiors.
We're dedicated and passionate about giving you the furniture and space you deserve, with a focus on
 three characteristics, 
 <br/>
 <br/>
<spam className="orangeSpam">•</spam>	reliability.

<spam className="orangeSpam">•</spam>	customer service
    
<spam className="orangeSpam">•</spam>	uniqueness 
<br/>
<br/>
Founded in 2014, The Factory has come a long way and we have big ambitions. 
We can take over the whole work process.
Measurement, design, modeling, selection of materials, preparation, transportation, maintenance, and installation,
We hope you enjoy our work as much as we enjoy offering them to you. 
for any further information please don't hesitate to contact us.
<br/>


</p>
            <a href="/about">more</a>
        </center>
    </div>
)

}

export default ToAbout;