import "../../style/Gallery.css"

function ToGallery(){

    return(
        <>
        <center>
        <h1>
            Our Work
        </h1>
        <div>
            <a href="/gallery/20"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/20.jpg"} /></a>
            <a href="/gallery/4"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/4.jpg"} /></a>
            <a href="/gallery/33"  className="listImgItemLink"> <img className="listImgItem" src={window.location.origin+"/gallery/33.jpg"} /></a>
        </div>
        <a href="/gallery">see more</a>
        </center>
        </>
    )
}

export default ToGallery;